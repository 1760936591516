import React from 'react';
import PropTypes from 'prop-types';
import LocaleContext from '@context/LocaleContext';
import Layout from '@components/Layout';
import SEO from '@components/seo';
import { graphql } from 'gatsby';
import localize from '@components/localize';
import CategoryFilter from '@components/CategoryFilter';
import TattooList from '@components/TattooList';

export const tattooPageFragment = graphql`
  fragment tattooPageFragment on SanityTattoo {
    id
    _type
    caption {
      _type
      en
      jp
    }
    category {
      id
      _type
      title {
        _type
        en
        jp
      }
    }
    date(formatString: "YYYY")
    cover {
      _type
      asset {
        _id
        _type
        fluid(maxWidth: 784) {
          ...GatsbySanityImageFluid_withWebp
        }
      }
    }
    images {
      _key
      _type
      asset {
        _id
        _type
        fluid(maxWidth: 784) {
          ...GatsbySanityImageFluid_withWebp
        }
      }
      altText
    }
    title {
      _type
      en
      jp
    }
  }
`;

export const tattooCategoryFragment = graphql`
  fragment tattooCategoryFragment on SanityTattooCategory {
    id
    _type
    title {
      _type
      en
      jp
    }
    slug {
      _type
      current
    }
  }
`;

export const query = graphql`
  query {
    allSanityTattoo(
      sort: {fields: date, order: DESC }
    ) {
      edges {
        node {
          ...tattooPageFragment
        }
      }
    }
    allSanityTattooCategory(
      sort: {fields: orderPriority, order: DESC }
    ) {
      edges {
        node {
          ...tattooCategoryFragment
        }
      }
    }
  }
`;

const TattooPage = ({
  data: { allSanityTattoo, allSanityTattooCategory },
  pageContext: { title, description, metaImage }
}) => {

  return (
    <LocaleContext.Consumer>
      {locale => (
        <Layout>
          <SEO title={title} description={description} image={metaImage?.asset.url} />
          <div className="pt-15 md:pt-20 bg-black"></div>
          <CategoryFilter heading={title} categories={allSanityTattooCategory.edges} />
          <hr className="border-grey-dark" />
          <div className="bg-black pt-12 md:pt-24 md:pb-16">
            {allSanityTattooCategory.edges.map(category => (
              <div key={category.node.id} className="mb-10 md:mb-25">
                <TattooList category={category} tattoos={allSanityTattoo.edges.filter(tattoo => tattoo.node.category.id === category.node.id )}/>
              </div>
            ))}
          </div>
          <hr className="border-grey-dark" />
        </Layout>
      )}
    </LocaleContext.Consumer>
  );
};

TattooPage.propTypes = {
  data: PropTypes.shape({
    allSanityTattoo: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allSanityTattooCategory: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metaImage: PropTypes.object,
  }),
};

TattooPage.defaultProps = {
  data: {
    allSanityTattoo: {},
    allSanityTattooCategory: {},
  },
  pageContext: {
    metaImage: {},
  },
};

export default localize(TattooPage);
